<template>
  <div class="unsubscribe-layout">
    <div class="unsubsribe-info-text">
      You've been successfully unsubscribed.
    </div>
  </div>
</template>

<script>
export default {
  name: "Unsubscribe",
  computed: {
    customerSlug() {
      return this.$route.query.customer_slug;
    },
  },
  mounted() {
    this.unsubscribeCustomer();
  },
  methods: {
    async unsubscribeCustomer() {
      if (!this.customerSlug) {
        return;
      }

      try {
        const formData = new FormData();
        formData.append("slug", this.customerSlug);

        await this.$http_client.post(
          "/public/customers/unsubscribe",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.$posthog.capture("unsubscribePage_customer_unsubscribed", {
          customer_slug: this.customerSlug,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.unsubscribe-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
  .unsubsribe-info-text {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
  }
}
</style>